(function($){
    // JS
    window.Contact = {

        // Contact form Recaptcha
        //======================================================================
        recaptchaReplace: function()
        {
            var contactRecaptcha = $('#recaptcha_contact');
            if (contactRecaptcha.length) {
                var recaptcha = $('#recaptcha').clone();
                $('#recaptcha').remove();

                recaptcha.appendTo(contactRecaptcha).show();
            };
        },


        // Form Validation using jQuery Validate
        //======================================================================
        formValidation: function()
        {

            $.validator.addMethod("generalphone", function(phone_number, element) {
                phone_number = phone_number.replace(/\s+/g, "");
                return this.optional(element) || phone_number.length > 9 && phone_number.length < 25 && phone_number.match('^([+]?)([0-9\-()])+$');
            }, "Please specify a valid phone number");


            $('#contactForm').validate({
                rules: {
                    contact_name: {
                        required: true
                    },
                    contact_email: {
                        required: true,
                        email: true
                    },
                    contact_telephone: {
                        required: true,
                        generalphone: true
                    },
                    contact_postcode: {
                        required: true
                    },
                    contact_content: {
                        required: true,
                        minlength: 20
                    }
                },
                ignore: '',
                onfocusout: function(element) {
                    $(element).valid();
                },
                highlight: function(element, errorClass, validClass) {
                    $(element).parent('.labelwrap').removeClass('formValid').addClass('formError');
                },
                unhighlight: function(element, errorClass, validClass) {
                    $(element).parent('.labelwrap').removeClass('formError').addClass('formValid');
                }
            });

            $('#retailForm').validate({
                rules: {
                    '00Nb0000009GEML': {
                        required: true
                    },
                    lead_source: {
                        required: true
                    },
                    '00Nb0000009Ga0W': {
                        required: true
                    },
                    company: {
                        required: true
                    },
                    '00Nb0000004BzxL': {
                        required: true
                    },
                    '00Nb0000004BzXx': {
                        required: true
                    },
                    first_name: {
                        required: true
                    },
                    last_name: {
                        required: true
                    },
                    phone: {
                        required: true,
                        generalphone: true
                    },
                    email: {
                        required: true,
                        email: true
                    }
                },
                ignore: '',
                onfocusout: function(element) {
                    $(element).valid();
                },
                highlight: function(element, errorClass, validClass) {
                    $(element).closest('.labelwrap').removeClass('formValid').addClass('formError');
                },
                unhighlight: function(element, errorClass, validClass) {
                    $(element).closest('.labelwrap').removeClass('formError').addClass('formValid');
                },
                errorPlacement: function(error, element) {

                    if ($(element).attr('name') == 'retailer_type' ){
                        error.appendTo('.labelwrap-radio');
                    } else {
                        error.insertAfter($(element));
                    }
                }
            });

            $('#complaintsForm').validate({
                rules: {
                    complaints_name: {
                        required: true
                    },
                    complaints_crn: {
                        required: true
                    },
                    complaints_postcode: {
                        required: true
                    },
                    complaints_email: {
                        required: true,
                        email: true
                    },
                    complaints_telephone: {
                        required: true,
                        generalphone: true
                    },
                    complaints_body: {
                        required: true
                    },
                    complaints_content: {
                        required: true,
                        maxlength: 1000,
                        minlength: 50
                    }
                },
                ignore: '',
                onfocusout: function(element) {
                    $(element).valid();
                },
                highlight: function(element, errorClass, validClass) {
                    $(element).parent('.labelwrap').removeClass('formValid').addClass('formError');
                },
                unhighlight: function(element, errorClass, validClass) {
                    $(element).parent('.labelwrap').removeClass('formError').addClass('formValid');
                }
            });

            $('#introducersForm').validate({
                rules: {
                    introducers_name: {
                        required: true
                    },
                    introducers_email: {
                        required: true,
                        email: true
                    },
                    introducers_telephone: {
                        required: true,
                        generalphone: true
                    },
                    introducers_company: {
                        required: true
                    },
                    introducers_content: {
                        required: true,
                        minlength: 50
                    }
                },
                ignore: '',
                onfocusout: function(element) {
                    $(element).valid();
                },
                highlight: function(element, errorClass, validClass) {
                    $(element).parent('.labelwrap').removeClass('formValid').addClass('formError');
                },
                unhighlight: function(element, errorClass, validClass) {
                    $(element).parent('.labelwrap').removeClass('formError').addClass('formValid');
                }
            });
        },


        // Auto Expand Textarea when user types
        //======================================================================
        autoexpandTextarea: function() {
            $('textarea.js-auto-size').textareaAutoSize();
        }


    }
    // Run
    $(function(){
        if ( $('body').hasClass('contact') ) {
            Contact.recaptchaReplace();
            Contact.formValidation();
            Contact.autoexpandTextarea();
        }
    });
}(jQuery));




