(function ($) {
    // JS
    window.How = {

        // Hero Video Functionality
        //======================================================================
        playVideo: function () {
            
            $('#videoHolder').addClass('start');
            $('.start').on('click', '.hero_vcontent', function () {
                console.log('ok');
                var holder = $('#videoHolder'),
                    wording = $('.playword');

                holder.removeClass('start').addClass('playVideo playing');
                //wording.text('resume');

                if ($("#videoHolder video").length < 1) {

                    $("#videoHolder").append(
                        '<video autoplay="autoplay" controls="controls" style="width: 100%" id="companyVideo">' +
                            '<source src="/assets/video/omni_capital.mp4" type="video/mp4" type="video/mp4; codecs=&quot;avc1.42E01E, mp4a.40.2&quot;"></source>' +
                            '<source src="/assets/video/omni_capital.webm" type="video/webm; codecs=&quot;vp8&quot;"></source>' +
                            '<source src="/assets/video/omni_capital.ogv" type="video/ogv" type="video/ogv; codecs=&quot;theora&quot;"></source>' +
                        '</video>'
                        );
                };

                document.getElementById('companyVideo').addEventListener('ended', myHandler, false);

                function myHandler(e) {
                    $('#videoHolder').removeClass('playing, playVideo ');
                    $('#videoHolder video').remove();
                }
            });

        },


        // Map Video to the scroll bar for the individual sections
        //======================================================================
        videoScrollbarMapping: function () {

            function iOS() {
                var iDevices = [
                    'iPad Simulator',
                    'iPhone Simulator',
                    'iPod Simulator',
                    'iPad',
                    'iPhone',
                    'iPod'
                ];
                while (iDevices.length) {
                    if (navigator.platform === iDevices.pop()) { return true; }
                }
                return false;
            }

            if (iOS() == true) {
                $('video').remove();
                $('.videoholder').addClass('ios');
            }

            if ($('body').is('.howitworks') && iOS() == false) {

                // Pause all the Videos
                var videos = document.querySelectorAll('video');

                for (var i = 0, len = videos.length; i < len; i++) {
                    var omniVideo = videos[i];
                    omniVideo.pause();
                }

                // Set up controller for ScrollMagic and loop through videos
                var controller = new ScrollMagic.Controller(),
                    getVideoset = $('.videobase'),
                    len = getVideoset.length;

                // Loop through videos
                getVideoset.each(function (index, element) {
                    // Information Variables
                    var $this = $(this),
                        videoID = $this.prev('video').attr('id'),
                        videoJQ = $('#' + videoID),
                        videoJS = document.getElementById(videoID),
                        videoDr = videoJQ.data('duration');

                    // Find the offset getting a different one for the last video
                    if (index == len - 1) {
                        var offset = $('body').innerHeight() - $('#playVideoCustom-base').offset().top;
                    } else {
                        var offset = window.innerHeight - ($('#playVideoLogo').innerHeight());
                    } 

                    // ScrollMagic functionality
                    var scene = new ScrollMagic.Scene({ triggerElement: $this, triggerHook: 'onEnter', duration: offset })
                        .addTo(controller)
                        .on('leave', function (event) {
                            // Fade video out only if the user scrolls back up the page
                            if (event.target.controller().info('scrollDirection') == 'REVERSE') {
                                videoJQ.removeClass('video_fade');
                            };
                        })
                        .on('progress', function (event) {
                            // fade in video when video comes into viewport
                            videoJQ.addClass('video_fade');
                            //set video time based on progress of ScrollMagic duration * video duration
                            requestAnimationFrame(function () {
                                videoJS.currentTime = event.progress.toFixed(3) * videoDr;
                            });
                        });
                });
            }
        },

        logoAnimation: function () {

            // top path
            //==================================================================
            //points exported from DrawScript : Bezier Points Array (anchor, control, control, anchor)
            var pathDataT = Snap.path.toCubic(document.getElementById('how_logopath-t').getAttribute('d'))
            dataLengthT = pathDataT.length,
            pointsT = [], //holds our series of x/y values for anchors and control points,
            pointsStringT = pathDataT.toString();

            // convert cubic data to GSAP bezier
            for (var iT = 0; iT < dataLengthT; iT++) {
                var segT = pathDataT[iT];
                if (segT[0] === "M") {
                    // move (starts the path)
                    var point2 = {};
                    point2.x = segT[1];
                    point2.y = segT[2];
                    pointsT.push(point2);
                } else {
                    // seg[0] === "C" (Snap.path.toCubic should return only curves after first point)
                    for (var jT = 1; jT < 6; jT += 2) {
                        var point2 = {};
                        point2.x = segT[jT];
                        point2.y = segT[jT + 1];
                        pointsT.push(point2);
                    }
                }
            }
            // bottom path
            //==================================================================
            var pathDataB = Snap.path.toCubic(document.getElementById('how_logopath-b').getAttribute('d'))
            dataLengthB = pathDataB.length,
            pointsB = [], //holds our series of x/y values for anchors and control points,
            pointsStringB = pathDataB.toString();

            // convert cubic data to GSAP bezier
            for (var iB = 0; iB < dataLengthB; iB++) {
                var segB = pathDataB[iB];
                if (segB[0] === "M") {
                    // move (starts the path)
                    var point2 = {};
                    point2.x = segB[1];
                    point2.y = segB[2];
                    pointsB.push(point2);
                } else {
                    // seg[0] === "C" (Snap.path.toCubic should return only curves after first point)
                    for (var jB = 1; jB < 6; jB += 2) {
                        var point2 = {};
                        point2.x = segB[jB];
                        point2.y = segB[jB + 1];
                        pointsB.push(point2);
                    }
                }
            }

            // Elements Vars
            //==================================================================
            var pathT = $("#how_logopath-t"),
                pathB = $("#how_logopath-b"),
                arrowT = $('#how_logoarrow-t'),
                arrowB = $('#how_logoarrow-b'),
                cutLine = $('#Line'),
                cutLine2 = $('#Line2'),
                bar01 = $('#bar'),
                bar02 = $('#bar2'),
                bar03 = $('#bar3'),
                bar04 = $('#bar4'),
                bar05 = $('#bar5'),
                bar06 = $('#bar6'),
                bar07 = $('#bar7'),
                bar08 = $('#bar8'),
                tweenDuration = $('.how_logo').innerHeight() * 3;

            // Functin to get line length
            //==================================================================
            function pathPrepare($el) {
                var lineLength = $el[0].getTotalLength();
                $el.css("stroke-dasharray", 0 + ' ' + lineLength);
                $el.css("stroke-dashoffset", lineLength);
            }
            function pathPrepareNorm($el) {
                var getLineLength = $el[0].getTotalLength();
                $el.css("stroke-dasharray", getLineLength);
                $el.css("stroke-dashoffset", getLineLength);
            }
            // Set up SVG line length
            pathPrepare(pathT);
            pathPrepare(pathB);

            pathPrepareNorm(cutLine);
            pathPrepareNorm(cutLine2);
            pathPrepareNorm(bar01);
            pathPrepareNorm(bar02);
            pathPrepareNorm(bar03);
            pathPrepareNorm(bar04);
            pathPrepareNorm(bar05);
            pathPrepareNorm(bar06);
            pathPrepareNorm(bar07);
            pathPrepareNorm(bar08);



            // Animation timeline
            //==================================================================
            var logoAni = new TimelineMax({ pause: true })
                // Set Arrows
                .set(arrowT, { opacity: 0, x: pointsT[0].x, y: pointsT[0].y, xPercent: -50, yPercent: -50 })
                .set(arrowB, { opacity: 0, x: pointsB[0].x, y: pointsB[0].y, xPercent: -50, yPercent: -50 })
                .set(pathB, { opacity: 0 })
                .set(pathT, { opacity: 0 })
                // Top Path
                .to(arrowT, 2, { opacity: 1, ease: Power0.easeNone })
                .to(arrowB, 2, { opacity: 1, ease: Power0.easeNone }, '-=2')
                .to(pathB, 2, { opacity: 1, ease: Power0.easeNone }, '-=2')
                .to(pathT, 2, { opacity: 1, ease: Power0.easeNone }, '-=2')
                .to(pathT, 20, { strokeDashoffset: 600, strokeDasharray: 300, ease: Linear.easeNone }, '-=2')
                .to(arrowT, 20, { bezier: { type: 'cubic', values: pointsT, autoRotate: true }, force3D: true, ease: Power0.easeNone }, '-=20')
                // Bottom Path
                .to(pathB, 20, { strokeDashoffset: 600, strokeDasharray: 300, ease: Linear.easeNone }, '-=20')
                .to(arrowB, 20, { bezier: { type: 'cubic', values: pointsB, autoRotate: true }, force3D: true, ease: Power0.easeNone }, '-=20')
                .to('#tailoredbg', 6, { width: 40, height: 40, ease: Linear.easeNone })
                .to('#tailoredbg', 6, { boxShadow: '0 0 0 21px #2588C7', ease: Linear.easeNone })
                .to('#tailoredbg', 1, { background: '#2588C7', ease: Linear.easeNone })
                .to('#tailoredbg', 6, { width: '100%', ease: Linear.easeNone })
                .to('#tailoredbg', 6, { height: '100%', borderRadius: 0, ease: Linear.easeNone })
                .to('.tailored_content', 6, { className: "+=active", top: 0, ease: Linear.easeNone })
                .to('#tailoredbg', 1, { className: '+=cut', background: 'transparent', boxShadow: '0 0 0 0 #fff' })
                .to('.secure', 1, { className: "+=active" }, '-=1')
                .to(cutLine, 20, { strokeDashoffset: 0, ease: Linear.easeNone }, '-=1')
                .to(cutLine, 20, { strokeDasharray: 10, ease: Linear.easeNone })
                .to('.ic-scissors', 2, { opacity: 1, ease: Linear.easeNone })
                .to('.ic-scissors', 20, { top: '0%', ease: Linear.easeNone })
                .to(cutLine2, 20, { strokeDashoffset: 0, ease: Linear.easeNone }, '-=20')
                .to('.leftside', 20, { borderRadius: '0 0 100% 0', ease: Linear.easeNone }, '-=20')
                .to('.rightside', 20, { borderRadius: '0 0 0 100%', ease: Linear.easeNone }, '-=20')
                .to('.tailored_content', 6, { opacity: '0', top: '-50px', ease: Linear.easeNone }, '-=10')
                .to('.ic-scissors', 6, { top: '-20%', ease: Linear.easeNone })
                .to(cutLine, 0, { opacity: 0 }, '-=1')
                .to(cutLine2, 0, { opacity: 0 })
                .to('.leftside', 20, { width: '0%', ease: Linear.easeNone }, '-=5')
                .to('.rightside', 20, { width: '0%', ease: Linear.easeNone }, '-=20')
                .to('#lock path', 10, { fill: '#2588C7', ease: Linear.easeNone })
                .to(bar01, 15, { strokeDashoffset: 0, ease: Linear.easeNone })
                .to(bar02, 18, { strokeDashoffset: 0, ease: Linear.easeNone }, '-=15')
                .to(bar03, 13, { strokeDashoffset: 0, ease: Linear.easeNone }, '-=18')
                .to(bar04, 18, { strokeDashoffset: 0, ease: Linear.easeNone }, '-=18')
                .to(bar05, 14, { strokeDashoffset: 0, ease: Linear.easeNone }, '-=18')
                .to(bar06, 17, { strokeDashoffset: 0, ease: Linear.easeNone }, '-=18')
                .to(bar07, 19, { strokeDashoffset: 0, ease: Linear.easeNone }, '-=18')
                .to(bar08, 14, { strokeDashoffset: 0, ease: Linear.easeNone }, '-=19')
            ;

            //Scrollmagic Scene
            //==================================================================
            var scene = new ScrollMagic.Scene({ triggerHook: 'onLeave', triggerElement: '#introtrigger > p', duration: tweenDuration })
                .setPin($('.howsect_pinned'), { pushFollowers: true })
                .addTo(Offer.controller);

            // Mobile
            $(window).bind('load resize', function () {

                if (Modernizr.mq('(max-width: 950px)')) {
                    scene.enabled(false).removeTween(true).removePin(true);
                    logoAni.pause(51);

                } else {
                    scene.enabled(true).setTween(logoAni).setPin($('.howsect_pinned'), { pushFollowers: true });
                    logoAni.play();
                }
            });


        },


        fastAnimation: function () {

            var tweenDuration = $('.fasttimer').innerHeight();

            // Animation timeline
            //==================================================================
            var fastAni = new TimelineMax()
                .set('.stopw_stop', { className: "+=active" })
                .set('.centre', { y: '-50%', x: '-50%' })
                .set('#timerMask', { rotation: -90, transformOrigin: '50% 50%' })
                .to('.centre', 10, { rotation: 90, ease: Linear.easeNone, transformOrigin: '50% 50%' })
                .to('#timerMask', 10, { rotation: 0, transformOrigin: '50% 50%', ease: Linear.easeNone }, '-=10')
                .to('.stopw_start', 10, { className: "+=active" }, '-=10')
                .to('.stopw_stop', 10, { className: "-=active" }, '-=10')
                .to('.stopw_start', 1, { className: "-=active" })
                .to('.stopw_stop', 1, { className: "+=active" }, '-=1')
            ;


            //Scrollmagic Scene
            //==================================================================

            var scene = new ScrollMagic.Scene({ triggerHook: 0.5, triggerElement: '.fasttimer', duration: tweenDuration })
                .addTo(Offer.controller);


            $(window).bind('load resize', function () {
                if (Modernizr.mq('(max-width: 600px)')) {
                    scene.enabled(false).removeTween(true);
                    fastAni.pause(11);
                } else {
                    scene.enabled(true).setTween(fastAni);
                    fastAni.play();
                }
            });

        },

        trackableAnimation: function (viewportH) {


            // Tracking Path
            //==================================================================
            var pathDataB = Snap.path.toCubic(document.getElementById('trackpath').getAttribute('d'))
            dataLengthB = pathDataB.length,
            pointsB = [], //holds our series of x/y values for anchors and control points,
            pointsStringB = pathDataB.toString();

            // convert cubic data to GSAP bezier
            for (var iB = 0; iB < dataLengthB; iB++) {
                var segB = pathDataB[iB];
                if (segB[0] === "M") {
                    // move (starts the path)
                    var point2 = {};
                    point2.x = segB[1];
                    point2.y = segB[2];
                    pointsB.push(point2);
                } else {
                    // seg[0] === "C" (Snap.path.toCubic should return only curves after first point)
                    for (var jB = 1; jB < 6; jB += 2) {
                        var point2 = {};
                        point2.x = segB[jB];
                        point2.y = segB[jB + 1];
                        pointsB.push(point2);
                    }
                }
            }

            var tweenDuration = $('#how_tracking').innerHeight();


            var trackAni = new TimelineMax()
                .set('#trackersvg', { x: pointsB[0].x, y: pointsB[0].y, xPercent: -50, yPercent: -50 })
                .to('.graph_move', 20, { xPercent: '-=50', ease: Linear.easeNone })
                .to('#trackersvg', 20, { bezier: { type: 'cubic', values: pointsB, autoRotate: false }, force3D: true, ease: Power0.easeNone }, '=-20')
            ;

            //Scrollmagic Scene
            //==================================================================
            var scene = new ScrollMagic.Scene({ triggerHook: 0.5, triggerElement: '#how_tracking', duration: tweenDuration })
               .addTo(Offer.controller);

            $(window).bind('load resize', function () {

                if (Modernizr.mq('(max-width: 600px)')) {
                    scene.enabled(false).removeTween(true);
                    trackAni.pause(1);
                } else {
                    scene.enabled(true).setTween(trackAni);
                    trackAni.play();
                }
            });


        },

        omniAnimation: function () {

            var tweenDuration = $('.integrated_wrap').outerHeight();


            // Animation timeline
            //==================================================================
            var omniAni = new TimelineMax()
                .to('.integrated_login', 5, { opacity: 0, ease: Linear.easeNone }, '+=2')
                .to('.integrated_home', 20, { backgroundPosition: "center 100%", ease: Linear.easeNone }, '+=2')
            ;


            //Scrollmagic Scene
            //==================================================================
            var scene = new ScrollMagic.Scene({ triggerHook: 0.5, triggerElement: '.integrated_wrap', duration: tweenDuration })
               .addTo(Offer.controller);

            $(window).bind('load resize', function () {
                if (Modernizr.mq('(max-width: 600px)')) {
                    scene.enabled(false).removeTween(true);
                    omniAni.pause(7);
                } else {
                    scene.enabled(true).setTween(omniAni);
                    omniAni.play();
                }
            });


        },

        aniIntro: function () {

            tweenDuration = ($('#introtrigger').innerHeight()) + 120;

            // Animation timeline
            //==================================================================
            var introAni = new TimelineMax()
                .to('.omni_intro', 5, { opacity: 0, ease: Linear.easeNone }, '+=2')
            ;


            //Scrollmagic Scene
            //==================================================================
            var scene = new ScrollMagic.Scene({ triggerHook: 0.1, triggerElement: '#introtrigger', duration: tweenDuration })
               .addTo(Offer.controller);


            $(window).bind('load resize', function () {
                if (Modernizr.mq('(max-width: 950px)')) {
                    scene.enabled(false).removeTween(true).removePin(true);
                    introAni.pause(0);

                } else {
                    scene.enabled(true).setTween(introAni);
                    introAni.play();
                }
            });

        },

        deviceMorph: function () {
            function b() {
                var b = $("#screen_inner")
                  , c = b.width()
                  , d = b.height()
                  , e = b.find("div");
                e.hasClass("rotated") ? e.css({
                    width: d,
                    height: c
                }) : e.removeAttr("style")
            }
            function c(a) {
                var b = a[0].getTotalLength();
                a.css("stroke-dasharray", b),
                a.css("stroke-dashoffset", b)
            }
            function d(a) {
                var b = a.data.scroll;
                a.preventDefault(),
                TweenLite.to(window, 2, {
                    scrollTo: {
                        y: b,
                        x: 0
                    },
                    ease: Power4.easeOut
                })
            }


            var f = $("path.path")
              , g = $("path.arrowhead");

            var h = new TimelineMax().to("#stand", 2, {
                y: "-100%",
                ease: Linear.easeNone
            }).set("#stand", {
                display: "none"
            }).to("#logo", 1, {
                opacity: 1,
                ease: Linear.easeNone
            }).to("#screen_outer", 2, {
                borderRadius: "25px",
                height: "100%",
                ease: Linear.easeNone
            }).to('[data-web-design="desktop"]', 2, {
                opacity: 1,
                ease: Linear.easeNone
            }, "-=2").to("#screen_inner", 2, {
                top: "0%",
                height: "79.5%",
                width: "84%",
                y: "12.5%",
                ease: Linear.easeNone
            }).set("#screen_inner", {
                className: "screen_inner_desktop "
            }, "-=2").set("#display", {
                background: "none",
                boxShadow: "none"
            }).to("#button", 2, {
                opacity: 1,
                ease: Linear.easeNone
            }).to('[data-web-design="tablet"]', 2, {
                opacity: 1,
                ease: Linear.easeNone
            }, "-=2").to("#screen_outer", 3, {
                rotation: 90,
                scale: .9,
                ease: Linear.easeNone
            }).set("#screen_inner", {
                className: "screen_inner_tablet",
                onComplete: b,
                onReverseComplete: b
            }, "-=2").to("#screen_inner", 2, {
                height: "89%",
                width: "80%",
                y: "8.0%",
                onUpdate: b,
                ease: Linear.ease,
                scale: 0.95,
            }, "-=2").to("#screen_outer", 2, {
                y: "8.0%",
                ease: Linear.easeNone
            }, "-=2").to("#screen_outer", 2, {
                borderRadius: "45px",
                scale: .7,
                height: "65%",
                width: "100%",
                y: "25%",
                x: "0%",
                onUpdate: b,
                ease: Linear.easeNone
            }).to("#screen_inner", 1, {
                height: "89%",
                width: "80%",
                y: "5.0%",
                onUpdate: b,
                ease: Linear.easeNone
            }, "-=2").to('[data-web-design="mobile"]', 1, {
                opacity: 1,
                ease: Linear.easeNone
            }, "-=2").set("#screen_inner", {
                className: "screen_inner_mobile",
                height: "100%",
                width: "80%",
                y: "11.5%",
                //scale: 1.1,
            }, "-=1").to("#button", 2, {
                x: "-75%",
                y: "-70%",
                ease: Linear.easeNone
            }, "-=1").to("#screen_inner", 1, {
                height: "100%",
                width: "80%",
                y: "2.5%",
                onUpdate: b,
                ease: Linear.easeNone,
                scale: 0.9,
            }, "-=2").to('[data-web-design="mobile"]', 1, {
                y: "5.0%",
                opacity: 1,
               // scale: .6,
                ease: Linear.easeNone
            }, "-=2").to(f, 1, {
                strokeDashoffset: 0,
                ease: Linear.easeNone
            }, "-=2").to(g, .5, {
                strokeDashoffset: 0,
                ease: Linear.easeNone
            }, "timelineEnd-=1");

            var scene = new ScrollMagic.Scene({
                triggerElement: "#webdesign",
                triggerHook: .3,
                duration: 2000
            }).addTo(Offer.controller);

            $(window).bind('load resize', function () {

                if (Modernizr.mq('(max-width: 600px)')) {
                    scene.enabled(false).removeTween(true);
                    h.pause(0);
                } else {
                    scene.enabled(true).setTween(h).setPin("#webdesign");
                    h.play();
                }
            });
        }
    }
    // Run
    $(function () {
        //if (window.location.href.indexOf("how_it_works") > -1) {
            var vph = $(window).innerHeight();

            How.playVideo();
            How.videoScrollbarMapping();
            // How.logoAnimation();
            // How.fastAnimation();
            // How.trackableAnimation(vph);
           // How.omniAnimation();
            //How.aniIntro();

            //How.deviceMorph();
        //}
    });
}(jQuery));




