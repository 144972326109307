(function ($) {
    // JS
    window.Site = {

        // Show hide content will scrollTo functionality
        //======================================================================
        revealJS: function () {
            $(document).on('click', '.reveal_trigger[data-reveal]', function () {
                var revealKey = $(this).data('reveal');
                revealContent = $('.reveal_content[data-reveal="' + revealKey + '"]');

                if (revealContent.is(':hidden')) {
                    $('.reveal_content-hide').slideUp();
                    $('.reveal_trigger').removeClass('open');
                    $(this).addClass('open');
                    revealContent.slideDown();
                } else {
                    $(this).removeClass('open');
                    revealContent.slideUp();
                }
            });

            $('.reveal_content-hide').hide();

            $(window).on('resize', function () {
                $('.reveal_content-remresize').removeAttr('style');
            });

        },


        // Placeholder Polyfill
        //======================================================================
        placeholdertext: function () {
            if ($('input').length) {

                $('input, textarea').each(function () {
                    if (!Modernizr.placeholder) {
                        var $this = $(this),
                            placeholderText = this.getAttribute('placeholder'),
                            initVal = ($this.val() !== '') ? $this.val() : placeholderText,
                            parentForm = $this.closest('form');

                        if (placeholderText) {
                            $this
                                .addClass('placeholder-text')
                                .val(initVal)
                                .on('focus', function () {
                                    if (this.value == placeholderText) {
                                        $this
                                            .val('')
                                            .removeClass('placeholder-text');
                                    }
                                })
                                .on('blur', function () {
                                    if (this.value == '') {
                                        $this
                                            .val(placeholderText)
                                            .addClass('placeholder-text');
                                    }
                                });

                            parentForm.on('submit', function () {
                                if ($this.val() == placeholderText) {
                                    $this.val('');
                                }
                            });
                        }
                    }
                });
            }
        },


        // News Function Filter
        //======================================================================

        locationSearch: function (e) {
            new URL(location.href).searchParams.get(e)
            var params = new URL(location.href).searchParams;
            return params.get(e);
        },

        filtersFormActions: function () {
            $('#categoryFilter').on('change', function () {
                $('#filtersForm').submit();
            });

            $('#sortFilter').on('change', function () {
                $('#filtersForm').submit();
            });

            //if (Site.locationSearch('categoryFilter') !== "") {
            //    $('#categoryFilter option').each(function () {
            //        if ($(this).val() === Site.locationSearch('categoryFilter')) {
            //            $(this).attr('selected', true);
            //        }
            //    });
            //};
            //if (Site.locationSearch('sortFilter') !== "") {
            //    $('#sortFilter option').each(function () {
            //        if ($(this).val() === Site.locationSearch('sortFilter')) {
            //            $(this).attr('selected', true);
            //        }
            //    });
            //};
           
        },

        changePhoneLink: function () {
            $(window).bind('load', function () {
                var w = window.innerWidth;
                if (w < 415) {
                    $('.apply_now_link').attr('href', 'tel:+4403332408318')
                }
            });
        },

        // Hero Image Parallax
        //======================================================================
        heroParallax: function () {


            $(window).bind('load resize', function () {
                /// Set Sectors "hero" size before parallax calculations
                var w = window.innerWidth;
                // Responsive Sectors pages head image => => =>
                var padding_top = 146;
                var sectors_bar_height;
                if (w < 368) {
                    sectors_bar_height = 164;
                } else if (w < 380) {
                    sectors_bar_height = 144;
                } else if (w < 552) {
                    sectors_bar_height = 164;
                } else if (w < 600) {
                    sectors_bar_height = 144;
                } else {
                    sectors_bar_height = 0;
                }

                if (w < 500) {
                    padding_top = 156;
                } else if (w < 600) {
                    padding_top = 165;
                } else {
                    sectors_bar_height = 0;
                    padding_top = 146;
                }
                $('.hero_sector')
                    .height(window.innerHeight - padding_top - sectors_bar_height)
                    .css('padding-top', padding_top);



                // Position arrow button: total height - elements above arrow height
                $('.arrow-table').height(
                    $('.hero_sector').outerHeight(true)
                    - (
                        165 // header menu height
                        + $('.parallax_sectors_subtitle').outerHeight(true)
                        + $('.parallax-sectors-title').outerHeight(true)
                        + $('.apply-now-container').outerHeight(true)
                        + window.innerHeight * 0.14 // .parallax-content  top:14vh
                        + 20 //bottom margin
                    )
                );
                // <= <= <= Responsive Sectors pages head image */

                var controllerContent = new ScrollMagic.Controller();

                // Animation timeline
                //==================================================================
                var parallax = new TimelineMax()
                    .set('.hero_parallaximg', { backgroundPositionY: '100%' })
                    .to('.hero_parallaximg', 3000, { backgroundPositionY: '0%', ease: Linear.easeNone });

                //Scrollmagic Scene
                //==================================================================
                var scenes = [];

                $.each($('.hero_imgwrap'), function (i, element) {
                    var tweenDuration = $(element).offset().top + $(element).innerHeight();
                    var scene = new ScrollMagic.Scene({ triggerHook: 'onLeave', triggerElement: '.pure-container', duration: tweenDuration })
                        .addTo(controllerContent);
                    scenes.push(scene);
                });

                /*   if ( Modernizr.mq('(max-width: 600px)') ) {
                       scenes.forEach(function(scene) {
                           scene.enabled(false).removeTween(true);
                       }, this);
                       parallax.pause(0);
                   } else { 
                       */
                scenes.forEach(function (scene) {
                    scene.enabled(true).setTween(parallax);
                }, this);
                parallax.play();
                //}
            });


        }

    }
    // Run
    $(function () {
        Site.revealJS();

        if (!$('body').hasClass('home')) {
            Site.placeholdertext();
            Site.heroParallax();
            Site.changePhoneLink();
        }

        Site.filtersFormActions();
        //if( $('body').hasClass('news') ){
        //}

    });

    var eventDrivenMac = false;
    var eventDrivenMonthlyInstallments = false;
    $('#mac-carousel').bind('slide.bs.carousel', function (e) {
        if (eventDrivenMac) {
            eventDrivenMac = false;
            return;
        }
        slideCarousel(e, "mac-indicators")
    });

    $("#mac-indicators li").click(function (e) {
        eventDrivenMac = true;
        indicatorClick(e, "mac-indicators");
    })


    $("#monthly-installments-carousel").bind('slide.bs.carousel', function (e) {
        if (eventDrivenMonthlyInstallments) {
            eventDrivenMonthlyInstallments = false;
            return;
        }
        slideCarousel(e, "monthly-installments-indicators");

    });

    $("#monthly-installments-indicators li").click(function (e) {
        eventDrivenMonthlyInstallments = true;
        indicatorClick(e, "monthly-installments-indicators");
    })

    function slideCarousel(e, indicatorsId) {
        var current = $(e.target).find('.item.active');
        $('#' + indicatorsId + ' li').removeClass('active')
        var indx = $(current).index();

        if ((indx + 2) > $('#' + indicatorsId + ' li').length) {
            indx = -1
        }
        $('#' + indicatorsId + ' li:nth-child(' + (indx + 2) + ')').addClass('active')
    }

    function indicatorClick(e, indicatorsId) {
        $('#' + indicatorsId + ' li').removeClass('active')
        $(e.target).addClass('active');
    }

}(jQuery));




