(function($){
    // JS
    window.Home = {
        // Show hide content will scrollTo functionality
        //======================================================================
        revealJS: function()
        {
            $(document).on('click', '.reveal_trigger[data-reveal]', function(){
                var revealKey = $(this).data('reveal');
                    revealContent = $('.reveal_content[data-reveal="' + revealKey + '"]');

                if(revealContent.is(':hidden')) {
                    $('.reveal_content-hide').slideUp();
                    $('.reveal_trigger').removeClass('open');
                    $(this).addClass('open');
                    revealContent.slideDown();
                } else {
                    $(this).removeClass('open');
                    revealContent.slideUp();
                }
            });

            $('.reveal_content-hide').hide();

            $(window).on('resize', function(){
                $('.reveal_content-remresize').removeAttr('style');
            });

        },

        // Homepage Carousel
        //======================================================================
        sectorCarousel: function()
        {
            // Create Carousel
            var owl = $('.sector-carousel');

            owl.owlCarousel({
                loop               : true,
                margin             : 0,
                nav                : true,
                autoplay           : true,
                autoplayTimeout    : 5000,
                autoplayHoverPause : true,
                autoplaySpeed      : 1000,
                navText            : ['',''],
                responsive: {
                    0 : {
                        items        : 2,
                        stagePadding : 0
                    },
                    600 : {
                        items        : 4
                    },
                    700 : {
                        items        : 5
                    },
                    1000 : {
                         items       : 9
                    },
                    1400 : {
                        items        : 9
                    }
                }
            });

            var img = $('.img-carousel');

            img.owlCarousel({
                center: true,
                loop: true,
                margin: 0,
                nav: true,
                autoplay: true,
                autoplayTimeout: 5000,
                autoplayHoverPause: true,
                autoplaySpeed: 1000,
                autoWidth: true,
                navText: ['', '']                
            });

            /*
                Function: setSlideHeight()
                Function to determine the slide height to ensure that the
                slider is always 100% height regardless of surrounding content
            */

            function setSlideHeight() {
                if($('.owl-item.active').length <= 4 ){
                    $('.home_sectors').removeAttr('style');
                }
                var dhH  = $('.header').outerHeight(),       // Desktop Header Height
                    winH = $(window).height(),               // Window Height
                    mmH  = $('.mobilemenu').outerHeight(),   // Mobile Menu Height
                    mhH  = $('.home_header').outerHeight();  // Mobile Header Height

                if ($('.header .logo').is(':visible')) {
                    // desktop
                    var slideH = winH - dhH ;
                } else if ( $('.mobilemenu').is(':visible') ) {
                    // mobile
                    var slideH = winH - (mhH + mmH);
                } else {
                    // tablet / in between
                    var slideH = winH  - (mhH + dhH);
                }

                if($('.owl-item.active').length > 4 ){
                    $('.home_sectors').css('height', slideH);
                }

                $('#slider-main .item').css('height', slideH);

            }

            // set slide height on load
            setSlideHeight();

            // reset slide height when carousel is resized
            owl.on('resized.owl.carousel', function(event) {
                setSlideHeight();
                $('.home_sectorlist').removeAttr('style');
            });


            if (Modernizr.mq('(min-width: 600px)')) {
                $('body').on({
                    mouseenter: function(e){
                        var scrollTop     = $('.home_sectors').scrollTop(),
                        elementOffset = $(this).offset().top,
                        distance      = (elementOffset - scrollTop);

                        $('.cut').css('top', distance );
                    },
                    mouseleave: function(e){
                        $('.cut').removeAttr('style');
                    }
                }, '.home_sectors .home_sectorlist a');
            }





        },

        iefallbackClass: function()
        {
            function getIEVersion(){
                var agent = navigator.userAgent;
                var reg = /MSIE\s?(\d+)(?:\.(\d+))?/i;
                var matches = agent.match(reg);
                if (matches != null) {
                    return { major: matches[1], minor: matches[2] };
                }
                return { major: "-1", minor: "-1" };
            }

            var ie_version =  getIEVersion();
            var is_ie10 = ie_version.major;
            var is_ie = (function(){for(var a=3,b=document.createElement("b"),c=b.all||[];b.innerHTML="<!--[if gt IE "+ ++a+"]><i><![endif]-->",c[0];);return 4<a?a:document.documentMode})();
            var isIE11 = !!navigator.userAgent.match(/Trident.*rv[ :]*11\./);

            if(is_ie || isIE11  ) {
                $('html').addClass('ie ie11');
            }

            if( is_ie || is_ie10 == 10) {
                $('html').addClass('ie ie10 lt-ie11');
            }
        }



    }
    // Run
    $(function(){
        if ( $('body').hasClass('home') ) {
            Home.revealJS();
            Home.sectorCarousel();
            Home.iefallbackClass();
        }
    });
    if (!(window.location.href.indexOf('sectors') > 0) && !(window.location.href.indexOf('about') > 0)) {
        Home.sectorCarousel();
    }
}(jQuery));




