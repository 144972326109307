(function ($) {

    $('.submitCustomerContactForm').click(function () {
        sendCustomerContactEmail();
        $('.customerForm').hide();
        $('.formSent').removeAttr('hidden');
        return false;
    });

    $('.submitRetailerContactForm').click(function () {
        sendRetailerContactEmail();
        $('.retailerForm').hide();
        $('.retailerFormSent').removeAttr('hidden');
        return false;
    });

    $('.submitComplaintsForm').click(function () {
        sendComplaintsFormEmail();
        $('.complaintsForm').hide();
        $('.complaintsFormSent').removeAttr('hidden');
        return false;
    });
    
    var sendCustomerContactEmail = function () {
        var contactData = {
            TypeOfForm: 'Customer Contact Form',
            FullName: document.getElementById("contact_name").value,
            Email: document.getElementById("contact_email").value,
            Telephone: document.getElementById("contact_telephone").value,
            Postcode: document.getElementById("contact_postcode").value,
            CustomerReferenceNumber: document.getElementById("contact_crn").value,
            Details: document.getElementById("contact_content").value
        };

        $.post('/umbraco/surface/CustomerContact/SubmitCustomerContactForm', contactData);
    };

    var sendComplaintsFormEmail = function () {
        var contactData = {
            TypeOfForm: 'Customer Complaint Form',
            FullName: document.getElementById("complaints_name").value,
            Email: document.getElementById("complaints_email").value,
            Telephone: document.getElementById("complaints_telephone").value,
            Postcode: document.getElementById("complaints_postcode").value,
            CustomerReferenceNumber: document.getElementById("complaints_crn").value,
            Details: document.getElementById("complaints_content").value,
            ComplaintOption: document.getElementById("complaints_type").value
        };

        $.post('/umbraco/surface/ComplaintsForm/SubmitComplaintsForm', contactData);
    };

    var sendRetailerContactEmail = function () {
        var retailerData = {
            TypeOfCompany: document.getElementById("retailer_toc").value,
            CompanyName: document.getElementById("retailer_company").value,
            CompanyRegNumber: document.getElementById("retailer_reg").value,
            Turnover: document.getElementById("retailer_turnover").value,
            FirstName: document.getElementById("retailer_firstName").value,
            LastName: document.getElementById("retailer_lastName").value,
            Telephone: document.getElementById("retailer_phone").value,
            Email: document.getElementById("retailer_email").value
        };
        $.post('/umbraco/surface/RetailerContact/SubmitRetailerForm', retailerData);
    };

})(jQuery);