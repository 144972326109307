(function($){
    // JS
    window.Sectors = {

        // Sector Breadcrumb that appears on all individual sector pages converts to slider on mobile
        //======================================================================
        sectorBreacrumb: function()
        {

            // Set current to active
            var activeDataSector = $('.sector_single').attr('data-sector');
            $('.sectorbc_link-' + activeDataSector).addClass('active');

            // On hover display titles on other items
            $('.sectorbc_item').on('mouseenter', function() {
                $('.sector-title span').hide()
                $('#' + $(this).attr('title-name')+'_title').show();
                //$(this)
                /*
                var w = window.innerWidth;
                if(!$(this).hasClass('active') && w >= 770) {
                    $(this).stop().animate({'max-width': 195}, 500);
                    $('.sectorbc_item.active').stop().animate({'max-width': 0}, 500);
                }
                */

            }).on('mouseleave', function(){
                $('.sector-title span').hide();
                var sectors_bar = $('.sectors-bar');
                if(sectors_bar!== undefined){
                    $('#' + sectors_bar.attr('title-name')+'_title').show();
                }
                /*
                var w = window.innerWidth;
                if(!$(this).hasClass('active') && w >= 770) {
                    $(this).stop().animate({'max-width': 0}, 500);
                    $('.sectorbc_item.active').stop().animate({'max-width': 195}, 500);
                }
                */
            });


            var sectorTitle;
            $('.about_sector_link_hover')
                .on('mouseenter', function() { 
                    $('#' + $(this).parent().attr('title-name')+'_title').show();
                    $(this).parent()
                    .find('img')
                    .animate({
                        width: "70%",
                        height: "70%",
                        opacity: 0.5,
                    });

            }).on('mouseleave', function() {
                $('#' + $(this).parent().attr('title-name') + '_title').hide();
                $(this).parent()
                .find('img').animate({
                    width: "100%",
                    height: "100%",
                    opacity: 1,
                });
            });

            var carousel = $('.sectorbc_list');


            $(window).bind('load resize', function(){
                var w = window.innerWidth;
                /*/ Responsive Sectors pages => => =>
                var padding_top = 146;
                var sectors_bar_height;
                if( w <  368){
                    sectors_bar_height = 164;
                }  else if (w <  380){
                    sectors_bar_height = 144;
                }   else if (w <  552){
                    sectors_bar_height = 164;
                }  else if (w <  600) {
                    sectors_bar_height = 144;
                }   else {
                    sectors_bar_height = 0;
                }

                if(w <  500){
                    padding_top = 156;
                } else if(w <  600){
                    padding_top = 165;
                } else {
                    sectors_bar_height = 0;
                    padding_top = 146;
                }
                $('.hero_sector')
                        .height(window.innerHeight - padding_top - sectors_bar_height)
                        .css('padding-top' , padding_top);
                
                        Position arrow button
                $('.arrow-table').height( $('.hero_sector').outerHeight(true)
                    - 165 // header menu height
                    - $('.parallax_sectors_subtitle').outerHeight(true)
                    -$('.parallax-sectors-title').outerHeight(true)
                    -$('.apply-now-container').outerHeight(true)
                    - window.innerHeight * 0.14 // .parallax-content  top:14vh
                    - 20 //bottom margin
                );
                <= <= <= Responsive Sectors pages */
                // if (w >= 1800) {
                //     carousel.trigger('destroy.owl.carousel');
                    
                // } else {
                   
                    var resp = {};
                    for(var i=1; i<=8;i++){
                        resp[i*(100)] = { items : i};
                    }                    
                    carousel.owlCarousel({
                        loop               : false,
                        nav                : false,
                        autoplay           : false,
                        margin             : 0,
                        navText            : ['',''],
                        responsive:   
                        
                        {
                            0 : {
                                items      : 1
                            },
                            380 : {
                                items      : 2
                            },
                            700: {
                                items: 5
                            },
                            1000: {
                                items: 8
                            },
                            1400: {
                                items: 8
                            }
                        }
                        
                    });
            });
        },


        // Mobile sector overview page converts list into slider
        //======================================================================
        sectorsCarousel : function()
        {
            var sectorCaro = $('.sector_carousel');

            $(window).bind('load resize', function(){
                var w = window.innerWidth;

                if (w >= 599) {
                    sectorCaro.trigger('destroy.owl.carousel');
                } else {
                    sectorCaro.owlCarousel({
                        loop               : false,
                        margin             : 0,
                        nav                : true,
                        autoplay           : false,
                        navText            : ['',''],
                        items              : 1
                    });
                }
            });
        }


    }
    // Run
    $(function(){
        if (window.location.href.indexOf('sectors') > 0) {
            Sectors.sectorBreacrumb();
            Sectors.sectorsCarousel();
        }
    });
}(jQuery));

// $(document).ready(function() { 
//     // Show Title in sectors bar
//     $('#' + $('.sectors-bar').attr('title-name')+'_title').show();
//  });